import {NgModule} from '@angular/core';
import {BrowserModule, provideClientHydration} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HomePageComponent} from './page/home/home-page.component';
import {ReviewDetailPageComponent} from './page/review/review-detail-page/review-detail-page.component';
import {AppRoutingModule} from './app-routing.module';
import {HeaderComponent} from '@core/component/header/header.component';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatGridListModule} from '@angular/material/grid-list';
import {ReviewCardComponent} from '@core/component/review-card/review-card.component';
import {HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch} from '@angular/common/http';
import {MatCardModule} from '@angular/material/card';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {FooterComponent} from '@core/component/footer/footer.component';
import {MatListModule} from '@angular/material/list';
import {ReviewItemPageComponent} from './page/review/review-item-page/review-item-page.component';
import {ReviewCardInlineComponent} from '@core/component/review-card-inline/review-card-inline.component';
import {CategoryPageComponent} from './page/category-page/category-page.component';
import {LoginComponent} from './page/login/login.component';
import {RegistrationComponent} from './page/registration/registration.component';
import {CircuitLoaderComponent} from '@core/component/circuit-loader/circuit-loader.component';
import {MessageModalComponent} from '@core/component/message-modal/message-modal.component';
import {NgOptimizedImage} from '@angular/common';
import {UserPageComponent} from './management/user-page/user-page.component';
import {UserReviewsPageComponent} from './management/user-reviews-page/user-reviews-page.component';
import {
  UserReviewModificationPageComponent
} from './management/user-review-modification-page/user-review-modification-page.component';
import {UserReviewCreateComponent} from './management/user-review-create/user-review-create.component';
import {NotFoundComponent} from './page/error/not-found/not-found.component';
import {UserReviewObjectsPageComponent} from './management/user-review-objects-page/user-review-objects-page.component';
import {
  UserReviewObjectCreateComponent
} from './management/user-review-object-create/user-review-object-create.component';
import {
  UserReviewObjectUpdateComponent
} from './management/user-review-object-update/user-review-object-update.component';

import {PaginatorComponent} from "@core/component/paginator/paginator.component";
import {
  SearchReviewObjectPageComponent
} from "./page/review/search-review-object-page/search-review-object-page.component";
import {
  ReviewObjectCardInlineComponent
} from "@core/component/review-object-card-inline/review-object-card-inline.component";
import {RatingComponent} from "@core/component/rating/rating.component";
import {CarouselImageComponent} from "@core/component/carousel-image/carousel-image.component";
import {BsModalService} from "ngx-bootstrap/modal";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {AuthInterceptor} from "@core/interceptor/auth-interceptor";
import {LikeDislikeComponent} from "@core/component/like-dislike/like-dislike.component";
import {ReviewEditorComponent} from "@core/component/review-editor/review-editor.component";
import {FormErrorListComponent} from "@core/component/form-error-list/form-error-list.component";
import {
  SimpleYandexAdBlockComponent
} from "@core/component/ads/simple-yandex-ad-block/simple-yandex-ad-block.component";
import {ProductStatisticComponent} from "@core/component/product-statistic/product-statistic.component";
import {RatingModule} from "ngx-bootstrap/rating";
import {FloorADSBlockComponent} from "@core/component/ads/floor-adsblock/floor-adsblock.component";

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    SearchReviewObjectPageComponent,
    ReviewDetailPageComponent,
    HeaderComponent,
    ReviewCardComponent,
    FooterComponent,
    ReviewItemPageComponent,
    ReviewCardInlineComponent,
    CategoryPageComponent,
    LoginComponent,
    RegistrationComponent,
    CircuitLoaderComponent,
    MessageModalComponent,
    UserPageComponent,
    UserReviewsPageComponent,
    UserReviewModificationPageComponent,
    UserReviewCreateComponent,
    NotFoundComponent,
    UserReviewObjectsPageComponent,
    UserReviewObjectCreateComponent,
    UserReviewObjectUpdateComponent,
    ReviewObjectCardInlineComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatGridListModule,
    HttpClientModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatListModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    PaginatorComponent,
    RatingComponent,
    CarouselImageComponent,
    BsDropdownModule,
    LikeDislikeComponent,
    ReviewEditorComponent,
    FormErrorListComponent,
    SimpleYandexAdBlockComponent,
    ProductStatisticComponent,
    RatingModule,
    FloorADSBlockComponent
  ],
  bootstrap: [AppComponent],
  providers: [
    BsModalService, provideClientHydration(),
    provideHttpClient(withFetch()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }],
})
export class AppModule {
}
