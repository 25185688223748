<div class="container">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12">
        <div class="review_main_data">
          <div class="form-group">
            <label for="reviewTopicTitle">Тема рецензии</label>
            <input class="form-control mb-2" id="reviewTopicTitle" formControlName="reviewTopicTitle"
                   name="reviewTopicTitle"
                   type="text">
            <app-form-error-list [formControlRef]="reviewTopicTitle"/>

            <div *ngIf="results.length > 0">
              <p>Похожие темы</p>
              <ol>
                <li *ngFor="let result of results">
                  <button mat-button [textContent]="result.name" (click)="fillTopicTitle(result)"></button>
                </li>
              </ol>
            </div>

          </div>

          <div class="form-group">
            <label for="reviewTopicCategory">Категория</label>
            <select class="form-control mb-2" id="reviewTopicCategory" formControlName="reviewTopicCategory"
                    name="reviewTopicCategory">
              <option [value]="category.id" *ngFor="let category of categoryList" [text]="category.name">

              </option>
            </select>

            <app-form-error-list [formControlRef]="reviewTopicCategory"/>
          </div>

          <p>Фотографии на обложке темы</p>
          <div class="d-flex justify-content-around">
            <div class="form-group" *ngFor="let titleImage of [1, 2, 3, 4, 5]">
              <label for="topic_title_img_{{titleImage}}">
                <img ngSrc="assets/img/no-image.jpg" alt="no_content" class="title_img_preview"
                     id="topic_title_img_preview_{{titleImage}}"
                     height="100" width="150" *ngIf="!this.topicImageUrls.get(titleImage)">

                <img src="{{this.topicImageUrls.get(titleImage)}}" alt="no_content" class="title_img_preview"
                     id="title_img_preview_{{titleImage}}"
                     height="100" width="150" *ngIf="this.topicImageUrls.get(titleImage)">
                <input class="form-control my-2 d-none"
                       id="topic_title_img_{{titleImage}}"
                       type="file"
                       formControlName="topicImage{{titleImage}}"
                       (change)="previewPhoto($event, 'topic', titleImage)">
              </label>
            </div>
          </div>

          <div class="form-group">
            <label for="reviewTopicDescription">Описание темы</label>
            <textarea class="form-control mb-2" id="reviewTopicDescription" formControlName="reviewTopicDescription"
                      name="reviewTopicDescription"
                      type="text"
                      rows="5">
            </textarea>

            <app-form-error-list [formControlRef]="reviewTopicDescription"/>
          </div>
          <div class="form-group">
            <label for="reviewTitle">Заголовок</label>
            <input class="form-control my-2" formControlName="reviewTitle"
                   id="reviewTitle"
                   type="text">

            <app-form-error-list [formControlRef]="reviewTitle"/>
          </div>

          <p>Фотографии на обложке</p>
          <div class="d-flex justify-content-around">
            <div class="form-group" *ngFor="let titleImage of [1, 2, 3, 4, 5]">
              <label for="review_title_img_{{titleImage}}}">
                <img ngSrc="assets/img/no-image.jpg" alt="no_content" class="title_img_preview"
                     id="review_title_img_preview_{{titleImage}}"
                     height="100" width="150" *ngIf="!this.reviewImageUrls.get(titleImage)">

                <img src="{{this.reviewImageUrls.get(titleImage)}}" alt="no_content" class="title_img_preview"
                     id="review_title_img_preview_{{titleImage}}"
                     height="100" width="150" *ngIf="this.reviewImageUrls.get(titleImage)">
                <input class="form-control my-2 d-none"
                       id="review_title_img_{{titleImage}}}"
                       type="file"
                       formControlName="reviewImage{{titleImage}}"
                       (change)="previewPhoto($event, 'review', titleImage)">
              </label>
            </div>
          </div>
          <div class="form-group mt-2">
            <label for="reviewShortDescription">Оценка</label>
            <div>
              <app-rating class="rating-field" (ratingClicked)="ratingChanged($event)"></app-rating>
            </div>
          </div>
          <div class="form-group">
            <label for="reviewShortDescription">Краткое описание</label>
            <textarea class="form-control mb-2" formControlName="reviewShortDescription"
                      id="reviewShortDescription"
                      rows="4"></textarea>

            <app-form-error-list [formControlRef]="reviewShortDescription"/>
          </div>
          <div class="form-group">
            <label for="reviewEditor" class="pb-1">Рецензия</label>
            <div class="editor_form_group border border-1 p-1">
              <app-review-editor id="reviewEditor"
                                 formControlName="reviewContent">
              </app-review-editor>

              <app-form-error-list [formControlRef]="reviewContent"/>
            </div>
          </div>
        </div>
        <div class="d-flex my-2">
          <button (click)="save()" class="mx-1" mat-raised-button>Сохранить</button>
        </div>
      </div>
    </div>
  </form>
</div>
