<div class="slider-container">
  <div class="slider-wrapper" [style.transform]="getTransform()">
    <div class="slider-item" *ngFor="let slide of slides; let i = index">
      <img class="slide__img_{{mode}} {{slide.slideClass}}"
           [src]="'/api/photo?url='+ getImageUrl(slide.image)" [alt]="slide.image.name"
           loading="lazy"/>
    </div>

    <div *ngIf="images.length === 0 && showNoImage">
      <img ngSrc="assets/img/no-image.jpg" height="179" width="275" alt="no_img" loading="lazy"/>
    </div>
  </div>

  <!-- Кнопки навигации -->
  <button *ngIf="images.length > 1" class="nav-button prev-button" (click)="onPreviousClick()">&#10094;</button>
  <button *ngIf="images.length > 1" class="nav-button next-button" (click)="onNextClick()">&#10095;</button>

  <!-- Индикаторы -->
  <div class="indicators">
    <span
      *ngFor="let i of visibleIndicators"
      [class.active]="i === currentIndex"
      (click)="selectImage(i)"></span>
  </div>
</div>
