<div class="d-flex align-items-center">
  <div class="d-flex align-items-center">
    <mat-icon (click)="clickLike()" *ngIf="review?.rateType == ReviewRateType.LIKE" class="liked">
      thumb_up_alt
    </mat-icon>
    <mat-icon (click)="clickLike()" *ngIf="!review?.rateType || review?.rateType != ReviewRateType.LIKE">thumb_up
    </mat-icon>
    <span class="m-2">{{review?.likes}}</span>
  </div>
  <div class="d-flex align-items-center">
    <mat-icon (click)="clickDislike()" *ngIf="review?.rateType == ReviewRateType.DISLIKE"
              class="disliked">thumb_down_alt
    </mat-icon>
    <mat-icon (click)="clickDislike()" *ngIf="!review?.rateType || review?.rateType != ReviewRateType.DISLIKE">
      thumb_down_alt
    </mat-icon>
    <span class="m-2">{{review?.dislikes}}</span></div>
</div>
