import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Reviewer} from '@core/model/reviewer';
import {Observable} from 'rxjs';
import {ReviewDetail, UserReview} from '@core/model/review';
import {Page} from '@core/model/page';

@Injectable({
  providedIn: 'root'
})
export class ReviewerService {
  baseUrl = '/api/reviewer';

  constructor(private http: HttpClient) {
  }

  getReviewerInfo(): Observable<Reviewer> {

    return this.http.get<Reviewer>(`${this.baseUrl}`);
  }

  getReviews(page: number, size: number): Observable<Page<UserReview>> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('page', page);
    queryParams = queryParams.append('size', size);

    return this.http.get<Page<UserReview>>(`${this.baseUrl}/reviews`, {
      params: queryParams
    });
  }

  getReviewById(id: number): Observable<ReviewDetail> {
    return this.http.get<ReviewDetail>(`${this.baseUrl}/review/${id}`);
  }

}
