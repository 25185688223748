import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {RegistrationForm} from '@core/model/auth/registration-form';
import {Observable, Subject} from 'rxjs';
import {AuthToken} from '@core/model/auth/auth-token';
import {LoginForm} from '@core/model/auth/login-form';
import {AuthInfo} from '@core/model/auth/auth-info';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = '/api/auth';
  private expireDate = 24 * 60 * 60 * 1000;
  private loginEvent: Subject<void> = new Subject<void>();

  constructor(private http: HttpClient,
              @Inject(PLATFORM_ID) private platformId: any) {
  }

  registration(form: RegistrationForm): Observable<AuthToken> {
    return this.http.post<AuthToken>(`${this.baseUrl}/registration`, form);
  }

  login(form: LoginForm): Observable<AuthToken> {
    return this.http.post<AuthToken>(`${this.baseUrl}/login`, form);
  }

  saveToken(token: AuthToken) {
    const authInfo = JSON.parse(localStorage.getItem('auth_info') ?? '{}') as AuthInfo;
    authInfo.token = token.token;
    authInfo.tokenType = token.tokenType;
    authInfo.createdDate = new Date();

    localStorage.setItem('auth_info', JSON.stringify(authInfo));
    this.loginEvent.next();
  }

  getAuthInfo(): AuthInfo {
    if (this.platformId === 'browser') {
      const authInfo = JSON.parse(localStorage.getItem('auth_info') ?? '{}') as AuthInfo;
      if (authInfo.createdDate) {
        authInfo.createdDate = new Date(Date.parse(`${authInfo.createdDate}`));
      }
      return authInfo;
    }
    return {
      token: '',
      tokenType: '',
      createdDate: new Date(0)
    } as AuthInfo
  }

  isAuth(): boolean {
    const authInfo = this.getAuthInfo();

    return authInfo && this.getAuthInfo().createdDate && (new Date().getTime() < (this.getAuthInfo().createdDate.getTime() + this.expireDate / 2));
  }

  logout() {
    localStorage.removeItem('auth_info');
  }

  scribeOnLoginEvent(next?: ((value: void) => void)) {
    this.loginEvent.subscribe(next)
  }
}
