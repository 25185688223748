<div class="container">
  <h3 class="text-center">Список рецензий</h3>

  <div class="row">
    <div class="col-md-3 col-12 my-2 d-block d-md-none">
      <div [class.mat-elevation-z1]="true" class="mt-3 add-review-container">
        <a [routerLink]="'/user/review/add'" class="w-100 mt-2" mat-stroked-button>Добавить рецензию</a>
      </div>

    </div>

    <div class="col-12 col-md-9">
      <div *ngFor="let review of userReviewsDataModel.pageReview?.content" [class.mat-elevation-z1]="true"
           class="user-review row">
        <div class="review_images col-3">
          <app-carousel-image *ngIf="review && review.media" [images]="review.media" mode="sm"></app-carousel-image>
        </div>
        <div class="review_content col-9">
          <a [routerLink]="'/user/review/' + review.id">{{ review.title }}</a>
          <p class="review_description">{{ review.shortDescription }}</p>
          <p class="review_status">
            <span [textContent]="getStatusName(review.status)"></span>
          </p>

        </div>
      </div>
      <p>1</p>
      <p>2</p>
      <p>3</p>
      <p>4</p>
      <div *ngIf="userReviewsDataModel.pageReview && userReviewsDataModel.pageReview.size"
           [class.mat-elevation-z1]="true"
           class="mb-2 page-container">
        <app-paginator (pageChange)="changePage($event)"
                       [hidePageSize]="false"
                       [pageSizeOptions]="[10, 25, 100]"
                       [totalPages]="userReviewsDataModel.pageReview.pages"
                       class="mt-2">
        </app-paginator>
      </div>
    </div>

    <div class="col-md-3 col-12 my-2 d-md-block d-none">
      <div [class.mat-elevation-z1]="true" class="mt-3 add-review-container">
        <a [routerLink]="'/user/review/add'" class="w-100 mt-2 py-4" mat-stroked-button>Добавить рецензию</a>
      </div>

    </div>
  </div>
</div>
