<div class="container p-3">
  <div class="row">
    <div class="col col-md-9">
      <div>
        <h5>Новые отзывы</h5>

        <div class="d-flex overflow-y-scroll my-2">
          <div *ngFor="let review of lastReviews" style="width: 350px;" >
            <app-review-card [review]="review" [sizeType]="'fixed'">
            </app-review-card>
          </div>
        </div>
      </div>

      <div *ngIf="isMobile()">
        <app-simple-yandex-ad-block></app-simple-yandex-ad-block>
      </div>

      <div class="mt-5">
        <h5>Лучшие отзывы</h5>
        <div>
          <div *ngFor="let review of bestReviews" class="mt-2">
            @switch (isMobile()){
              @case (true){
                <app-review-card [review]="review">
                </app-review-card>
              }
              @default {
                <app-review-card-inline [review]="review">
                </app-review-card-inline>
              }
            }
          </div>
        </div>
        <div class="mt-2">
          <button (click)="nextBestReviews()" class="w-100 bg-white" mat-stroked-button>
            Посмотреть еще
          </button>
        </div>
      </div>
    </div>

    <div class="d-none d-md-block col-md-3 mt-4" *ngIf="!isMobile()">
      <app-simple-yandex-ad-block></app-simple-yandex-ad-block>
    </div>
  </div>

  <app-floor-adsblock/>
</div>
