import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ReviewTopic} from '@core/model/review';
import {Page} from '@core/model/page';
import {ReviewObjectFilter} from '@core/model/review-object-filter';

@Injectable({
  providedIn: 'root'
})
export class ReviewTopicService {
  baseUrl = '/api/review-topic';

  constructor(private http: HttpClient) {
  }

  getReviewTopic(url: string): Observable<ReviewTopic> {
    return this.http.get<ReviewTopic>(`${this.baseUrl}/${decodeURIComponent(url)}`);
  }

  searchReviewObject(filter: ReviewObjectFilter): Observable<Page<ReviewTopic>> {
    let queryParams = new HttpParams();
    if (filter.q) {
      queryParams = queryParams.append('q', filter.q);
    }
    if (filter.sort) {
      queryParams = queryParams.append('sort', filter.sort);
    }
    if (filter.category) {
      queryParams = queryParams.append('category', filter.category);
    }
    if (filter.page) {
      queryParams = queryParams.append('page', filter.page);
    }
    if (filter.size) {
      queryParams = queryParams.append('size', filter.size);
    }
    return this.http.get<Page<ReviewTopic>>(`${this.baseUrl}/search`, {params: queryParams});
  }
}
