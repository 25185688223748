import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {RegistrationForm} from '@core/model/auth/registration-form';
import {AuthService} from '@core/service/auth.service';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {CircuitLoaderComponent} from "@core/component/circuit-loader/circuit-loader.component";
import {MessageModalComponent} from "@core/component/message-modal/message-modal.component";

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent {
  form: FormGroup;

  constructor(private fb: FormBuilder,
              private authService: AuthService,
              private router: Router,
              private modalService: BsModalService) {
    this.form = this.fb.group({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8)
      ]),
      password_confirmation: new FormControl('', [Validators.required, Validators.minLength(8)])
    });
  }

  get name() {
    return this.form.get('name');
  }

  get email() {
    return this.form.get('email');
  }

  get password() {
    return this.form.get('password');
  }

  get passwordConfirmation() {
    return this.form.get('password_confirmation');
  }

  registration() {
    if (this.form.get('name')?.valid) {
      const modal: BsModalRef = this.modalService.show(CircuitLoaderComponent, {
        animated: true,
        keyboard: false, class: 'modal-sm'
      });

      const registrationData = {
        email: this.form.get('email')?.value,
        name: this.form.get('name')?.value,
        password: this.form.get('password')?.value,
        passwordConfirmation: this.form.get('password_confirmation')?.value
      } as RegistrationForm;

      this.authService.registration(registrationData).subscribe({
        next: value => {
          this.authService.saveToken(value);
          this.router.navigate(['/user']).then();
        },
        error: (err) => {
          modal.hide();
          const message = this.modalService.show(MessageModalComponent);
          message.content?.setMessage(err.error);
          message.content?.setModalId(message.id);
        },
        complete: () => modal.hide()
      });
    }
  }
}
