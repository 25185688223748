<div class="p-2">
  <div class="header-control">
    <button (click)="close()" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <p [textContent]="question" class="text-center"></p>

  <div class="d-flex justify-content-around">
    <button mat-stroked-button (click)="onClickYes()">Да</button>
    <button mat-stroked-button (click)="onClickNo()">Нет</button>
  </div>
</div>
