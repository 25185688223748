import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReviewRateType} from "@core/model/enums/review-rate-type";
import {MatIconModule} from "@angular/material/icon";
import {Review} from "@core/model/review";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {MessageModalComponent} from "@core/component/message-modal/message-modal.component";
import {ReviewRateService} from "@core/service/review-rate-service";
import {AuthService} from "@core/service/auth.service";

@Component({
  selector: 'app-like-dislike',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './like-dislike.component.html',
  styleUrl: './like-dislike.component.css'
})
export class LikeDislikeComponent {

  @Input()
  public review?: Review;
  protected readonly ReviewRateType = ReviewRateType;

  constructor(private reviewRateService: ReviewRateService,
              private authService: AuthService,
              private modalService: BsModalService) {
  }

  clickLike() {
    if (this.authCheck() && this.review) {
      if (!this.review?.rateType || this.review?.rateType != ReviewRateType.LIKE) {
        this.addLike(this.review.id);
      } else if (this.review?.rateType == ReviewRateType.LIKE) {
        this.removeLike(this.review.id);
      }
    }
  }

  clickDislike() {
    if (this.authCheck() && this.review) {
      if (!this.review?.rateType || this.review?.rateType != ReviewRateType.DISLIKE) {
        this.addDislike(this.review.id);
      } else if (this.review?.rateType == ReviewRateType.DISLIKE) {
        this.removeDislike(this.review.id);
      }
    }
  }

  addLike(reviewId: number) {
    this.reviewRateService.addLike(reviewId)
    .subscribe(() => {
      if (this.review) {
        if (this.review?.rateType == ReviewRateType.DISLIKE) {
          this.review.dislikes = Math.max(0, this.review.dislikes - 1);
        }
        this.review.likes++;
        this.review.rateType = ReviewRateType.LIKE;
      }
    });
  }

  removeLike(reviewId: number) {
    this.reviewRateService.removeLike(reviewId).subscribe(() => {
      if (this.review) {
        this.review.rateType = undefined;
        this.review.likes = Math.max(0, this.review.likes - 1);
      }
    });
  }

  addDislike(reviewId: number) {
    this.reviewRateService.addDislike(reviewId)
    .subscribe(() => {
      if (this.review) {
        if (this.review?.rateType == ReviewRateType.LIKE) {
          this.review.likes = Math.max(0, this.review.likes - 1);
        }
        this.review.dislikes++;
        this.review.rateType = ReviewRateType.DISLIKE;
      }
    });
  }

  removeDislike(reviewId: number) {
    this.reviewRateService.removeDislike(reviewId).subscribe(() => {
      if (this.review) {
        this.review.rateType = undefined;
        this.review.dislikes = Math.max(0, this.review.dislikes - 1);
      }
    });
  }

  authCheck() {
    if (!this.authService.isAuth()) {
      const modal: BsModalRef = this.modalService.show(MessageModalComponent, {
        animated: true,
        keyboard: false, class: 'modal-sm'
      });
      modal.content.setMessage("user_is_not_authenticated");
      modal.content?.setModalId(modal.id);
      return false;
    }

    return true;
  }
}
