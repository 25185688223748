import {Component} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {Subject} from "rxjs";
import {Media} from "@core/model/review";
import {PhotoService} from "@core/service/photo.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MessageModalComponent} from "@core/component/message-modal/message-modal.component";

@Component({
  selector: 'app-upload-photo-modal',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, MatButtonModule, MatIconModule],
  templateUrl: './upload-photo-modal.component.html',
  styleUrl: './upload-photo-modal.component.css'
})
export class UploadPhotoModalComponent {
  photoLoaded?: Subject<Media>;
  file?: File;
  imageUrl?: string;

  constructor(private photoService: PhotoService,
              private modalService: BsModalService) {
  }

  setPhotoLoaded(photoLoaded: Subject<Media>) {
    this.photoLoaded = photoLoaded;
  }


  uploadPhoto() {
    if (this.file) {
      this.photoService.uploadPhoto(this.file)
      .subscribe({
        next: media => {
          this.photoLoaded?.next(media);
          this.modalService.hide();
        },
        error: (err) => {
          const message = this.modalService.show(MessageModalComponent,
            {class: 'modal-dialog-centered'});
          message.content?.setMessage(err.status === 401 ? 'user_is_not_authenticated' : err.statusText);
          message.content?.setModalId(message.id);
        }
      });
    }
  }

  previewPhoto(event: any) {
    this.file = event.target.files[0];
    if (this.file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.imageUrl = e.target.result;
      };

      reader.readAsDataURL(this.file);
    }
  }

  close() {
    this.modalService.hide();
  }

}
