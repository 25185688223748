import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {debounceTime, distinctUntilChanged, filter} from "rxjs";
import {ReviewTopicService} from "@core/service/review-topic.service";
import {ReviewTopic} from "@core/model/review";
import {BsModalService} from "ngx-bootstrap/modal";
import {YesNoModalComponent} from "@core/component/yes-no-modal/yes-no-modal.component";
import {ReviewService} from "@core/service/review.service";
import {Category} from "@core/model/category";
import {CategoryService} from "@core/service/category.service";
import {MessageModalComponent} from "@core/component/message-modal/message-modal.component";

@Component({
  selector: 'app-user-review-create',
  templateUrl: './user-review-create.component.html',
  styleUrls: ['./user-review-create.component.css']
})
export class UserReviewCreateComponent implements OnInit {
  form: FormGroup;
  results: ReviewTopic[] = [];
  lastSearch: string = '';
  reviewMedias: Map<number, File> = new Map();
  reviewImageUrls: Map<number, string> = new Map();

  topicMedias: Map<number, File> = new Map();
  topicImageUrls: Map<number, string> = new Map();
  topicUrl?: string;

  categoryList: Category[] = [];

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private reviewService: ReviewService,
              private topicService: ReviewTopicService,
              private modalService: BsModalService,
              private categoryService: CategoryService) {
    this.form = this.fb.group({
      reviewTopicTitle: new FormControl('', [
        Validators.required, Validators.minLength(5), Validators.maxLength(250)
      ]),
      reviewTopicCategory: new FormControl('', [
        Validators.required
      ]),
      reviewTopicDescription: new FormControl('', [Validators.required]),
      topicImage1: new FormControl('', []),
      topicImage2: new FormControl('', []),
      topicImage3: new FormControl('', []),
      topicImage4: new FormControl('', []),
      topicImage5: new FormControl('', []),
      reviewTitle: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(250)
      ]),
      reviewRating: new FormControl('', []),
      reviewShortDescription: new FormControl('', [
        Validators.required,
        Validators.minLength(150),
        Validators.maxLength(500)
      ]),
      reviewContent: new FormControl('', [Validators.required, Validators.minLength(500)]),
      reviewImage1: new FormControl('', []),
      reviewImage2: new FormControl('', []),
      reviewImage3: new FormControl('', []),
      reviewImage4: new FormControl('', []),
      reviewImage5: new FormControl('', []),
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['reviewTopicUrl']) {
        this.topicUrl = params['reviewTopicUrl'];
        this.loadReviewTopic(this.topicUrl ?? '');
      } else {
        this.categoryService.getCatalog()
        .subscribe(categories => this.categoryList = this.categoryService.catalogToCategories(categories));
      }
    });
    this.searchChangeInit();
  }

  loadReviewTopic(url: string) {
    this.categoryService.getCatalog().subscribe(categories => {
      this.categoryList = this.categoryService.catalogToCategories(categories);

      this.topicService.getReviewTopic(url).subscribe(topic => {
        (this.form.get("reviewTopicTitle") as FormControl).setValue(topic.name);
        (this.form.get("reviewTopicCategory") as FormControl).setValue(topic.category.id);
        (this.form.get("reviewTopicDescription") as FormControl).setValue(topic.shortDescription);

        this.form.get("reviewTopicDescription")?.disable();
        this.form.get("reviewTopicTitle")?.disable();
        this.form.get("reviewTopicCategory")?.disable();
        this.form.get("topicImage1")?.disable();
        this.form.get("topicImage2")?.disable();
        this.form.get("topicImage3")?.disable();
        this.form.get("topicImage4")?.disable();
        this.form.get("topicImage5")?.disable();


        topic.media.forEach((value, index) => {
          this.topicImageUrls.set(index + 1, `/api/photo?url=${value.largeImage}`)
        });
        this.lastSearch = topic.name;
        this.results = [];
      });


    })
  }

  save() {
    if (this.form.valid) {
      let yesNoModal = this.modalService.show(YesNoModalComponent, {
        initialState: {
          onClickYes: () => {
            this.reviewService.saveReview({
              topicUrl: this.topicUrl,
              topicTitle: this.form.get('reviewTopicTitle')?.value,
              topicDescription: this.form.get('reviewTopicDescription')?.value,
              topicCategoryId: this.form.get('reviewTopicCategory')?.value,
              topicImages: [...this.topicMedias.values()],
              reviewRating: this.form.get("reviewRating")?.value,
              reviewTitle: this.form.get('reviewTitle')?.value,
              reviewText: this.form.get('reviewContent')?.value,
              reviewShortDescription: this.form.get('reviewShortDescription')?.value,
              reviewCoverImages: [...this.reviewMedias.values()],
            }).subscribe({
              next: value => {
                this.router.navigate([`/user/review/${value.id}`]).then()
              },
              error: err => {
                let messageModal = this.modalService.show(MessageModalComponent)
                messageModal?.content?.setModalId(messageModal.id);
                messageModal?.content?.setMessages(JSON.parse(err.error.detail) as string[]);
              }
            });
            this.modalService.hide(yesNoModal.id);
          },
          onClickNo: () => {
            this.modalService.hide(yesNoModal.id);
          },
          question: "Хотите сохранить рецензию и отправить на модерацию? \nПосле этого рецензию нельзя будет отредактировать."
        }
      });
    }
  }

  searchChangeInit(){
    (this.form.get("reviewTopicTitle") as FormControl).valueChanges
    .pipe(
      debounceTime(500), // ждем 500мс после последнего ввода перед запросом
      filter(term => term.length >= 3),
      distinctUntilChanged(), // игнорируем, если предыдущее значение такое же
    ).subscribe(value => {
      this.search(value)
    });
  }

  search(title: string) {
    if (this.lastSearch != title) {
      this.topicService.searchReviewObject({
        q: title,
        page: 1,
        size: 5,
        sort: 'MOST_SIMILAR'
      }).subscribe(results => {
        this.results = results.content
      });
    }
    this.lastSearch = title;
  }

  fillTopicTitle(reviewTopic: ReviewTopic) {
    let yesNoModal = this.modalService.show(YesNoModalComponent, {
      initialState: {
        onClickYes: () => {
          this.router.navigate(['user/review/add'], {queryParams: {'reviewTopicUrl': reviewTopic.url}})
          .then();
          this.modalService.hide(yesNoModal.id);
        },
        onClickNo: () => {
          this.modalService.hide(yesNoModal.id);
        }, question: "Хотите написать рецензию на эту тему?"
      }
    });
  }


  previewPhoto(event: any, type: string, imgPreviewId: number) {
    if (type === 'review') {
      this.previewPhotoForReview(event, imgPreviewId);
    } else {
      this.previewPhotoForTopic(event, imgPreviewId);
    }
  }

  previewPhotoForReview(event: any, imgPreviewId: number) {
    this.reviewMedias.set(imgPreviewId, event.target.files[0]);
    if (this.reviewMedias.get(imgPreviewId)) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.reviewImageUrls.set(imgPreviewId, e.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  previewPhotoForTopic(event: any, imgPreviewId: number) {
    this.topicMedias.set(imgPreviewId, event.target.files[0]);
    if (this.topicMedias.get(imgPreviewId)) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.topicImageUrls.set(imgPreviewId, e.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }


  get reviewTopicTitle() {
    return this.form.get('reviewTopicTitle');
  }

  get reviewTopicCategory() {
    return this.form.get('reviewTopicCategory');
  }

  get reviewTopicDescription() {
    return this.form.get('reviewTopicDescription');
  }

  get reviewTitle() {
    return this.form.get('reviewTitle');
  }

  get reviewShortDescription() {
    return this.form.get('reviewShortDescription');
  }

  get reviewContent() {
    return this.form.get('reviewContent');
  }

  ratingChanged(rating: number) {
    console.log(rating)
    this.form.get("reviewRating")?.setValue(rating);
  }
}
