import {Component, Input} from '@angular/core';
import {Review} from '@core/model/review';

@Component({
  selector: 'app-review-card-inline',
  templateUrl: './review-card-inline.component.html',
  styleUrls: ['./review-card-inline.component.css']
})
export class ReviewCardInlineComponent {
  @Input()
  public review: Review | undefined;

  ngOnInit(): void {
    if (this.review) {
      this.review.likes = this.review?.likes ?? 0;
      this.review.dislikes = this.review?.dislikes ?? 0;
      this.review.stars = this.review?.stars ?? 0;
    }
  }

  getUrl() {
    return encodeURIComponent(this.review?.url ?? '')
  }
}
