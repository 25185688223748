import {Component, forwardRef, OnDestroy} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import {Editor, NgxEditorModule, toHTML, Toolbar} from "ngx-editor";
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule} from "@angular/forms";
import {ReviewEditorMenuComponent} from "@core/component/review-editor-menu/review-editor-menu.component";


@Component({
  selector: 'app-review-editor',
  standalone: true,
  imports: [CommonModule, MatButtonModule, NgxEditorModule, FormsModule, ReactiveFormsModule, ReviewEditorMenuComponent],
  templateUrl: './review-editor.component.html',
  styleUrl: './review-editor.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReviewEditorComponent),
      multi: true
    }
  ]
})
export class ReviewEditorComponent implements OnDestroy, ControlValueAccessor {
  editor: Editor;
  value: string = '';
  isDisabled: boolean = true;

  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{heading: ['h2', 'h3', 'h4', 'h5', 'h6']}],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify']
  ];

  constructor() {
    this.editor = new Editor({
      features: {linkOnPaste: true, resizeImage: true},
      attributes: {},
      content: '',
      plugins: [],
      nodeViews: {},
      history: true,
      keyboardShortcuts: true,
      inputRules: true,
    });


    this.editor.valueChanges.subscribe(value => {
      this.value = toHTML(value);
      this.onChange(toHTML(value));
    });
  }

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  writeValue(value: any): void {
    this.value = value;
    this.editor.setContent(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngOnDestroy(): void {
    if (this.editor) {
      this.editor.destroy();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    console.log("isDisabled = " + isDisabled)
    this.editor.view.editable = !isDisabled;
  }


}
