import {Component, Input} from '@angular/core';
import {BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.css']
})
export class MessageModalComponent {
  @Input()
  public messages: string[] = [];

  public modalId: string | number | undefined;

  translateMap : Map<string, string> = new Map();



  constructor(private modalService: BsModalService) {
    this.translateMap.set("topic_with_name_already_exists", "Тема с таким названием уже существует")
    this.translateMap.set("topic_images_must_not_be_empty", "Тема должна содержать хотябы одну фотку на обложке")
    this.translateMap.set("topic_title_must_not_be_blank", "Название темы не должно быть пустым")
    this.translateMap.set("topic_title_max_value_500", "Название темы не должно содержать больше 500 символов")
    this.translateMap.set("topic_title_min_value_5", "Название темы не должно содержать меньше 5 символов")
    this.translateMap.set("topic_description_must_not_be_blank", "Описание темы не должно быть пустым")
    this.translateMap.set("topic_description_max_value_500", "Описание темы не должно содержать больше 500 символов")
    this.translateMap.set("topic_description_min_value_150", "Описание темы не должно содержать меньше 150 символов")
    this.translateMap.set("topic_category_is_required", "Необходимо заполнить категорию")
    this.translateMap.set("review_with_title_already_exists", "Рецензия с таким заголовоком уже существует")
    this.translateMap.set("review_title_must_not_be_blank", "Заголовок рецензии не должен быть пустым")
    this.translateMap.set("review_title_max_value_500", "Заголовок рецензии не должен быть больше 500 символов")
    this.translateMap.set("review_title_min_value_5", "Заголовок рецензии не должен быть меньше 5 символов")
    this.translateMap.set("review_short_description_must_not_be_blank", "Краткое описание рецензии не должно быть пустым")
    this.translateMap.set("review_short_description_max_value_500", "Краткое описание рецензии не должно содержать больше 500 символов")
    this.translateMap.set("review_short_description_min_value_150", "Краткое описание рецензии не должно содержать меньше 150 символов")
    this.translateMap.set("review_text_must_not_be_blank", "Рецензия не должна быть пустой")
    this.translateMap.set("review_text_min_value_500", "Рецензия не должна содержать меньше 500 символов")
    this.translateMap.set("review_cover_images_must_not_be_empty", "Рецензия должна содержать хотябы одну фотку на обложке")
    this.translateMap.set("review_rating_must_not_be_empty", "Рецензия должна содержать оценку")
    this.translateMap.set("review_rating_must_be_between_1_and_5", "Оценка рецензии должна быть между 1 и 5")

    this.translateMap.set("password_not_confirmed", "Пароли не совпадают")
    this.translateMap.set("user_already_exists", "Пользователь с таким email уже существует")
    this.translateMap.set("Unauthorized", "Email или пароль не верны")
    this.translateMap.set("user_is_not_authenticated", "Вы не авторизованны")
  }

  setMessage(val: string) {
    this.messages.push(this.translate(val));
  }

  setMessages(messages: string[]){
    for(let message of messages){
      this.messages.push(this.translate(message));
    }
  }

  setModalId(modalId: string | number | undefined) {
    this.modalId = modalId;
  }

  close() {
    this.modalService.hide(this.modalId);
  }

  translate(message: string) {
    return this.translateMap.get(message) ?? message;
  }
}
