<div *ngIf="review" class="product-card product-card-width-{{sizeType}}">
  <div class="card">
    <div class="product-card__carousel-wrapper">
      <app-carousel-image *ngIf="review && review.media" [images]="review.media" [showNoImage]="true" mode="sm"></app-carousel-image>
    </div>

    <div class="card-body">
      <h5><a class="card-title" routerLink="/review/{{getUrl()}}" routerLinkActive="active">{{review.title}}</a></h5>
      <p class="card-text product-card__text">{{review.shortDescription}}</p>

      <div class="d-flex justify-content-between">
        <app-like-dislike [review]="review"></app-like-dislike>
        <div>
          <app-rating [readonly]="true" [selectedRating]="review.stars"></app-rating>
        </div>
      </div>
    </div>
  </div>
</div>
