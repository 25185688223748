import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageConfig} from "@core/model/page-config";

@Component({
  selector: 'app-paginator',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './paginator.component.html',
  styleUrl: './paginator.component.css'
})
export class PaginatorComponent {
  @Input() public totalPages: number = 0;
  @Input() public pageSizeOptions: number[] = [10, 25, 50];
  @Input() public showPages = 5;
  @Input() public hidePageSize: boolean = false;
  @Input() public currentPage: number = 1;
  @Output() pageChange = new EventEmitter<PageConfig>();

  calculatedPages(totalPages: number, currentPage: number, maxPages: number): number[] {
    const pages: number[] = [];

    let startPage: number, endPage: number;
    if (totalPages <= maxPages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      const halfMaxPages = Math.floor(maxPages / 2);
      if (currentPage <= halfMaxPages) {
        startPage = 1;
        endPage = maxPages;
      } else if (currentPage + halfMaxPages >= totalPages) {
        startPage = totalPages - maxPages + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - halfMaxPages;
        endPage = currentPage + halfMaxPages;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.pageChange.emit(this.getConfig());
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.pageChange.emit(this.getConfig());
    }
  }

  goPage(p: number) {
    this.currentPage = p;
    this.pageChange.emit(this.getConfig());
  }

  getConfig() {
    return {
      pageSize: 10,
      pageSizeOptions: this.pageSizeOptions,
      showPages: this.showPages,
      hidePageSize: this.hidePageSize,
      currentPage: this.currentPage
    } as PageConfig;
  }
}
