<div class="wrapper">
  <div class="header-control">
    <button (click)="close()" mat-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="review_object__photo">
    <input (change)="previewPhoto($event)" type="file" id="image_input">

    <label class="mt-2 d-flex justify-content-center" for="image_input">
      <img alt="preview" id="image_preview" [src]="imageUrl"
           *ngIf="imageUrl">
    </label>

    <div class="mt-2">
      <button mat-raised-button color="primary" class="upload_btn"
              (click)="uploadPhoto()" *ngIf="this.file">Загрузить</button>
    </div>
  </div>
</div>
