<div [class.mat-elevation-z1]="true" class="paginator_wrapper">
  <div class="d-flex justify-content-between align-items-center">
    <div>
      <button (click)="prevPage()" class="btn-page"><</button>
      <ng-container *ngFor="let page of calculatedPages(totalPages, currentPage, showPages)">
        <button *ngIf="page===currentPage" class="btn-page active">{{page}}</button>
        <button (click)="goPage(page)" *ngIf="page!==currentPage" class="btn-page">{{page}}</button>
      </ng-container>
      <button (click)="nextPage()" class="btn-page">></button>
    </div>

    <div *ngIf="!hidePageSize">
      <span>Всего: {{totalPages}}</span>
    </div>
  </div>
</div>
