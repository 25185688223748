<div *ngIf="lastPrice != undefined && lastRating != undefined">
  <h3 class="text-center">{{ title }}</h3>
  <hr>
  <div class="row">
    <div class="col-lg-6 col-12">
      <p class="mb-0">Цена: {{ lastPrice }}</p>
      <canvas *ngIf="isBrowser" baseChart [data]="priceChartData" [type]="'line'" [options]="lineChartOptions"></canvas>
    </div>
    <div class="col-lg-6 col-12">
      <p class="mb-0">Рейтинг: {{ lastRating }}</p>
      <canvas *ngIf="isBrowser" baseChart [data]="ratingChartData" [type]="'line'"
              [options]="ratingLineChartOptions"></canvas>
    </div>
  </div>
</div>
