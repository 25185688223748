import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Media} from "@core/model/review";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class PhotoService {
  baseUrl = '/api/photo';

  constructor(private http: HttpClient) {
  }

  uploadPhoto(file: File): Observable<Media> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<Media>(`${this.baseUrl}`, formData);
  }
}
