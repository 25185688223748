import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {CommonModule, isPlatformBrowser} from '@angular/common';
import {ProductStatistic} from "@core/model/kaspi/product-statistic";
import {BaseChartDirective, provideCharts, withDefaultRegisterables} from "ng2-charts";
import {ChartConfiguration, ChartData} from "chart.js";
import {ChartDataset} from "chart.js/dist/types";

@Component({
  selector: 'app-kaspi-statistic',
  standalone: true,
  imports: [CommonModule, BaseChartDirective],
  templateUrl: './product-statistic.component.html',
  styleUrl: './product-statistic.component.css',
  providers: [provideCharts(withDefaultRegisterables())]
})
export class ProductStatisticComponent implements OnInit {
  @Input()
  productStatistic?: ProductStatistic;
  isBrowser = false;

  @Input()
  title?: string;

  lastPrice?: number;
  lastRating?: number;

  labels: any = [];

  priceChartData: ChartData<'line'> = {
    labels: this.labels,
    datasets: [],
  };

  ratingChartData: ChartData<'line'> = {
    labels: this.labels,
    datasets: [],
    yLabels: [1, 2, 3, 4, 5]
  };

  lineChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    normalized: true,
  };

  ratingLineChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    normalized: true,
    scales: {
      y: {
        beginAtZero: false, // Начинать ось Y с 0 или нет
        min: 1,  // Минимальное значение оси Y
        max: 5, // Максимальное значение оси Y
        ticks: {
          stepSize: 1 // Шаг между делениями оси Y
        }
      }
    }
  };

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {
    if (this.productStatistic && this.productStatistic.priceHistory.length > 0) {
      this.lastPrice = this.productStatistic.priceHistory[this.productStatistic.priceHistory.length - 1].price;
      this.lastRating = this.productStatistic.ratingHistory[this.productStatistic.ratingHistory.length - 1].rating;

      this.priceChartRendering();
      this.ratingChartRendering();
    }
  }


  priceChartRendering() {
    const priceHistory = this.productStatistic?.priceHistory ?? [];
    const dataset: ChartDataset<'line', number[]> = {
      label: 'Изменение цены',
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1,
      data: priceHistory.map((item) => item.price) as number[]
    }

    this.priceChartData.labels = priceHistory.map((item) => item.date)

    this.priceChartData.datasets.pop();
    this.priceChartData.datasets.push(dataset);
  }

  ratingChartRendering() {
    const dataset: ChartDataset<'line', number[]> = {
      label: 'Изменение рейтинга',
      fill: false,
      borderColor: 'rgb(245,209,50)',
      tension: 0.1,
      data: this.productStatistic?.ratingHistory.map((item) => item.rating) as number[]
    }

    this.ratingChartData.labels = this.productStatistic?.ratingHistory.map((item) => item.date)

    this.ratingChartData.datasets.pop();
    this.ratingChartData.datasets.push(dataset);
  }
}
