<div class="container mt-2 mb-3">
  <div class="row">
    <div class="col-md-9 col-12">
      <h3>Каталог отзывов</h3>
      <div [class.mat-elevation-z1]="true" class="catalog-container">
        <div class="row">
          <div *ngFor="let category of catalog" class="col-md-6 col-12 mt-2">
            <a [queryParams]="{category: category.id}" [routerLink]="['/reviews']" class="h6">{{category.name}}</a>
            <div class="parent-category">
              <div *ngFor="let childCategory of category.categories" class="col">
                <a [queryParams]="{category: childCategory.id}" [routerLink]="['/reviews']">{{childCategory.name}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
