import {Component, Input} from '@angular/core';
import {ReviewerService} from '@core/service/reviewer.service';
import {UserReviewsDataModel} from '@core/model/page/reviewer/user-reviews-data-model';
import {getStatusName} from "@core/model/enums/review-status";

@Component({
  selector: 'app-user-reviews-page',
  templateUrl: './user-reviews-page.component.html',
  styleUrls: ['./user-reviews-page.component.css']
})
export class UserReviewsPageComponent {
  @Input()
  userReviewsDataModel = {
    page: 1,
    size: 10
  } as UserReviewsDataModel;

  constructor(private reviewerService: ReviewerService) {
    this.reviewerService.getReviews(this.userReviewsDataModel.page, this.userReviewsDataModel.size)
    .subscribe(page => this.userReviewsDataModel.pageReview = page);
  }

  changePage(page: any) {
    page = page as { previousPageIndex: number, pageIndex: number, pageSize: number, length: number };
    console.log(page)
    this.userReviewsDataModel.page = page.currentPage;
    this.userReviewsDataModel.size = page.pageSize;

    this.reviewerService.getReviews(this.userReviewsDataModel.page, this.userReviewsDataModel.size)
    .subscribe(pageReview => this.userReviewsDataModel.pageReview = pageReview);
  }

  protected readonly getStatusName = getStatusName;
}
