import {Component, Input, OnInit} from '@angular/core';
import {ReviewerService} from '@core/service/reviewer.service';
import {Reviewer} from '@core/model/reviewer';

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.css']
})
export class UserPageComponent implements OnInit {
  @Input()
  reviewer?: Reviewer;

  constructor(private reviewerService: ReviewerService) {
  }

  ngOnInit(): void {
    this.reviewerService.getReviewerInfo()
    .subscribe(value => this.reviewer = value);
  }
}
