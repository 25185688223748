<div class="container">
  <div class="row">
    <div class="col-md-9 col-12">
      <div *ngIf="searchPageDataModel?.childCategories" class="category-container mt-1">
        <div *ngFor="let category of searchPageDataModel.childCategories" class="category-item bg-white">
          <button (click)="changeCategory(category.id)" class="btn m-0">{{category.name}}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div *ngIf="reviews" class="col-md-9 col-12 mt-2">
      <div class="d-flex justify-content-between mb-2 align-items-center">
        <div>
          <nav *ngIf="reviewObjectFilter?.category" aria-label="breadcrumb">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item">
                <button (click)="clearCategory()" class="btn m-0 p-0">Все</button>
              </li>
              <ng-container *ngIf="reviewObjectFilter?.category">
                <li *ngFor="let category of searchPageDataModel.categoryBreadcrumb" class="breadcrumb-item">
                  <button (click)="changeCategory(category.id)" class="btn m-0 p-0">{{category.name}}</button>
                </li>
              </ng-container>
            </ol>
          </nav>
        </div>
        <div class="btn-group" dropdown>
          <button aria-controls="dropdown-sorting" bsDropdownToggle
                  class="btn btn-sm dropdown-toggle"
                  type="button">
            {{selectedSortingString()}}<span class="caret"></span>
          </button>
          <ul *bsDropdownMenu class="dropdown-menu" id="dropdown-sorting"
              role="menu">
            <li *ngIf="this.reviewObjectFilter?.q" role="menuitem">
              <span (click)="sortBy('MOST_SIMILAR')" class="dropdown-item">Сначала похожие</span>
            </li>
            <li role="menuitem">
              <span (click)="sortBy('POPULAR')" class="dropdown-item">Сначала популярные</span>
            </li>
            <li role="menuitem">
              <span (click)="sortBy('BEST')" class="dropdown-item">Сначала лучшие</span>
            </li>
          </ul>
        </div>
      </div>

      <div *ngFor="let r of reviews.content" class="mb-2">
        <app-review-object-card-inline [reviewTopic]="r"></app-review-object-card-inline>
      </div>
    </div>
    <div class="col-md-3 col-12">
      <app-simple-yandex-ad-block></app-simple-yandex-ad-block>
    </div>
    <div *ngIf="reviews" class="row mt-3">
      <div class="col-md-9 col-12">
        <div [class.mat-elevation-z1]="true" class="mb-2 page-container">
          <app-paginator (pageChange)="changePage($event)"
                         [hidePageSize]="false"
                         [pageSizeOptions]="[10, 25, 100]"
                         [totalPages]="reviews.pages"
                         class="mt-2">
          </app-paginator>
        </div>
        <div class="col-3"></div>
      </div>
    </div>
  </div>
</div>

<app-floor-adsblock/>
