<div class="container">
  <div class="row">
    <div class="offset-md-3 col-md-6 col">
      <div [class.mat-elevation-z1]="true" class="registration-container">
        <h5>Регистрация</h5>
        <form [formGroup]="form">
          <div class="form-group mt-2">
            <label for="name">Имя</label>
            <input class="form-control {{name?.touched ? (name?.invalid ? 'is-invalid': 'is-valid') : ''}}" formControlName="name"
                   id="name"
                   type="text">

            <div *ngIf="name?.touched && name?.invalid" class="invalid-feedback">
              Имя не должно быть пустым
            </div>
          </div>
          <div class="form-group mt-2">
            <label for="email">Email</label>
            <input class="form-control {{email?.touched? (email?.invalid ?'is-invalid': 'is-valid'): ''}}" formControlName="email"
                   id="email"
                   type="email">

            <div *ngIf="email?.touched && email?.invalid" class="invalid-feedback">
              Email является невалидным
            </div>
          </div>
          <div class="form-group mt-2">
            <label for="password">Пароль</label>
            <input class="form-control {{password?.touched ? (password?.invalid ? 'is-invalid': 'is-valid'): ''}}" formControlName="password"
                   id="password"
                   minlength="8" type="password">
            <div *ngIf="password?.touched && password?.invalid" class="invalid-feedback">
              Пароль является невалидным
              <ul>
                <li *ngIf="password?.errors?.['minlength']">Пароль должен содержать от 8 знаков</li>
              </ul>
            </div>
          </div>
          <div class="form-group mt-2">
            <label for="password_confirmation">Повторить пароль</label>
            <input class="form-control {{passwordConfirmation?.touched ? (passwordConfirmation?.invalid? 'is-invalid': 'is-valid') : ''}}" formControlName="password_confirmation"
                   id="password_confirmation"
                   minlength="8"
                   type="password">
            <div *ngIf="passwordConfirmation?.touched && passwordConfirmation?.invalid" class="invalid-feedback">
              Пароль является невалидным
              <ul>
                <li *ngIf="passwordConfirmation?.errors?.['minlength']">Пароль должен содержать от 8 знаков</li>
              </ul>
            </div>
          </div>
          <button (click)="registration()" class="w-100 mt-2" color="primary" mat-flat-button type="submit">
            Регистрация
          </button>
          <div class="login-link-container">
            <a class="login-link" routerLink="/login">Войти</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
