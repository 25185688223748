<div class="container">
  <div class="row">
    <div class="col">
      <div class="reviewer-info">
        <div class="row">
          <div class="col-md-4 col">
            <div class="reviewer-icon-container">
              <img *ngIf="reviewer?.iconLink" [src]="reviewer?.iconLink" alt="reviewer_img" class="reviewer-icon"
                   loading="lazy">
              <img *ngIf="!reviewer?.iconLink" [src]="'assets/img/no-image.jpg'" alt="no-image"
                   class="rounded-circle reviewer-icon" loading="lazy">
            </div>
          </div>
          <div class="col-md-8 col">
            <h3>{{ reviewer?.name }}</h3>
            <p>Email: <span>{{ reviewer?.email }}</span></p>
          </div>
        </div>
        <mat-divider class="my-3"></mat-divider>
        <div class="row">
          <div class="col-md-4 col">
          </div>
          <div class="col-md-8 col">
            <div class="d-flex justify-content-end">
              <a mat-raised-button color="secondary" class="mx-2 py-4 text-decoration-none" routerLink="/user/reviews">Все рецензии</a>
              <button mat-raised-button color="secondary" class="mx-2 py-4">Изменить фото</button>
              <button mat-raised-button color="secondary" class="mx-2 py-4">Изменить пароль</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
