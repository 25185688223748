import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ReviewService} from '@core/service/review.service';
import {ReviewDetail} from '@core/model/review';
import {DomSanitizer, Meta, Title} from "@angular/platform-browser";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'app-product-detail-page',
  templateUrl: './review-detail-page.component.html',
  styleUrls: ['./review-detail-page.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewDetailPageComponent implements OnInit {
  @Input()
  review?: ReviewDetail;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private reviewService: ReviewService,
    private metaTagService: Meta,
    private deviceService: DeviceDetectorService,
    private title: Title,
    private changeDetector: ChangeDetectorRef,
    private sanitizer: DomSanitizer) {

  }

  ngOnInit(): void {
    const reviewUrl = this.route.snapshot.paramMap.get('reviewUrl');
    if (reviewUrl != null && reviewUrl.length > 0) {
      this.reviewService.getReview(reviewUrl)
      .subscribe(
        {
          next: value => {
            this.review = value;
            this.metaTagService.updateTag({name: 'description', content: this.review.metaDescription})
            if (this.review.title) {
              this.title.setTitle(this.review.title)
            }
            if (!this.review.userInfo.iconLink) {
              this.review.userInfo.iconLink = 'assets/img/no-image.jpg';
            }

            this.changeDetector.markForCheck();
          },
          error: () => {
            this.router.navigate(['not-found'], {replaceUrl: true})
            .then();
          }
        }
      );
    }
  }

  getUrl() {
    return encodeURIComponent(this.review?.reviewTopic?.url ?? '')
  }

  isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  trustHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
