import {Component, Inject, isDevMode, OnInit, PLATFORM_ID, Renderer2, RendererFactory2} from '@angular/core';
import {CommonModule, isPlatformBrowser} from '@angular/common';
import {environment} from "../../../../../environments/environment";


@Component({
  selector: 'app-simple-yandex-ad-block',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './simple-yandex-ad-block.component.html',
  styleUrl: './simple-yandex-ad-block.component.css'
})
export class SimpleYandexAdBlockComponent implements OnInit {
  private renderer: Renderer2;
  isBrowser = false;

  constructor(public rendererFactory: RendererFactory2,
              @Inject(PLATFORM_ID) private platformId: any) {
    this.isBrowser  = isPlatformBrowser(this.platformId);
    this.renderer = rendererFactory.createRenderer(null, null);

  }

  ngOnInit() {
    this.loadScript();
  }

  loadScript(): void {
    if (!isDevMode() && this.isBrowser && environment.showAds) {
      const script = this.renderer.createElement('script');
      script.text = `
      window.yaContextCb.push(()=>{
        Ya.Context.AdvManager.render({
          "blockId": "R-A-9462968-1",
          "renderTo": "yandex_rtb_R-A-9462968-1"
        })
      })
    `;
      this.renderer.appendChild(document.body, script);
    }
  }

  protected readonly isDevMode = isDevMode;
  protected readonly environment = environment;
}
