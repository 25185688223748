import {Component} from '@angular/core';

@Component({
  selector: 'app-circuit-loader',
  templateUrl: './circuit-loader.component.html',
  styleUrls: ['./circuit-loader.component.css']
})
export class CircuitLoaderComponent {

}
