import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '@core/service/auth.service';
import {Router} from '@angular/router';
import {LoginForm} from '@core/model/auth/login-form';
import {CircuitLoaderComponent} from "@core/component/circuit-loader/circuit-loader.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {MessageModalComponent} from "@core/component/message-modal/message-modal.component";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  form: FormGroup;

  constructor(private fb: FormBuilder,
              private modalService: BsModalService,
              private authService: AuthService,
              private router: Router) {
    this.form = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });
  }

  login() {
    if (this.form.valid) {
      const modal: BsModalRef = this.modalService.show(CircuitLoaderComponent, {
        animated: true,
        keyboard: false, class: 'modal-sm'
      });

      const loginForm = {
        email: this.form.get('email')?.value,
        password: this.form.get('password')?.value
      } as LoginForm;
      this.authService.login(loginForm).subscribe({
        next: value => {
          this.authService.saveToken(value);
          this.router.navigate(['/user']).then();
        },
        error: (err) => {
          modal.hide();
          const message = this.modalService.show(MessageModalComponent);
          message.content?.setMessage(err.error.error);
          message.content?.setModalId(message.id);
        },
        complete: () => modal.hide()
      });
    }
  }
}
