<div *ngIf="review" [class.mat-elevation-z1]="true" class="review-card-inline">
  <div class="d-flex">
    <div class="carousel-wrapper">
      <app-carousel-image *ngIf="review && review.media" [images]="review.media" [mode]="'md'" [showNoImage]="true"></app-carousel-image>
    </div>

    <div class="card-body flex-grow-1">
      <h5><a class="card-title" routerLink="/review/{{getUrl()}}" routerLinkActive="active">{{review.title}}</a></h5>
      <p class="card-text review-card-inline__text">{{review.shortDescription}}</p>

      <div class="d-flex align-items-end justify-content-between">
        <app-like-dislike [review]="review"></app-like-dislike>

        <div class="h-100">
          <p class="mb-2">{{review.publishedDate | date : 'yyyy-MM-dd HH:mm' }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
