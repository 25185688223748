import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-yes-no-modal',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  templateUrl: './yes-no-modal.component.html',
  styleUrl: './yes-no-modal.component.css'
})
export class YesNoModalComponent {
  constructor(private modalService: BsModalService) {
  }

  onClickYes: any = () => {
  };
  onClickNo: any = () => {
  };

  question: string = '';


  close() {
    this.modalService.hide()
  }
}
