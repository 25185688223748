import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ReviewTopicService} from '@core/service/review-topic.service';
import {Review, ReviewTopic} from '@core/model/review';
import {ActivatedRoute, Router} from '@angular/router';
import {ReviewService} from '@core/service/review.service';
import {Page} from '@core/model/page';
import {DomSanitizer, Meta, Title} from "@angular/platform-browser";
import {AuthService} from "@core/service/auth.service";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'app-review-item-page',
  templateUrl: './review-item-page.component.html',
  styleUrls: ['./review-item-page.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewItemPageComponent implements OnInit {
  @Input()
  reviewTopic?: ReviewTopic;

  @Input()
  reviews?: Page<Review>;

  @Input()
  pageSize = 10;

  @Input()
  topicUrl?: string;

  isAuth: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private reviewObjectService: ReviewTopicService,
    private reviewService: ReviewService,
    private metaTagService: Meta,
    private authService: AuthService,
    private deviceService: DeviceDetectorService,
    private title: Title,
    private changeDetector: ChangeDetectorRef,
    private sanitizer: DomSanitizer) {

    this.isAuth = authService.isAuth();
    this.topicUrl = this.route.snapshot.paramMap.get('topicUrl') ?? undefined;
  }

  ngOnInit(): void {

    if (this.topicUrl != null) {
      this.reviewObjectService.getReviewTopic(this.topicUrl)
      .subscribe({
        next: value => {
          this.reviewService.getReviewByTopic(value.url, 1, this.pageSize)
          .subscribe({
            next: reviews => {
              this.reviews = reviews;
              this.changeDetector.markForCheck();
            },
            error: error => {
              this.changeDetector.markForCheck();
            }
          });
          this.reviewTopic = value;
          this.metaTagService.updateTag({name: 'description', content: this.reviewTopic.metaDescription});
          if (this.reviewTopic.name) {
            this.title.setTitle(this.reviewTopic.name)
          }
        },
        error: () => {
          this.router.navigate(['not-found'], {replaceUrl: true})
          .then();
        }
      });
    }

    this.isAuth = this.authService.isAuth();
  }

  changePage(page: any) {
    page = page as { previousPageIndex: number, pageIndex: number, pageSize: number, length: number };
    if (this.reviewTopic?.url) {
      this.reviewService.getReviewByTopic(this.reviewTopic?.url, page.pageIndex + 1, page.pageSize)
      .subscribe(reviews => {
        this.reviews = reviews;
        this.changeDetector.markForCheck();
      });
    }
  }

  isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  trustHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
