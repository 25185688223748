import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-rating',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rating.component.html',
  styleUrl: './rating.component.css'
})
export class RatingComponent {
  @Input() maxRating: number = 5;
  @Input() selectedRating: number = 0;
  @Input() readonly: boolean = false;

  @Output() ratingClicked = new EventEmitter<number>();

  ratingArray: number[] = [];

  ngOnInit() {
    this.ratingArray = Array.from({length: this.maxRating}, (_, index) => index + 1);
  }

  onRatingClicked(rating: number) {
    if (!this.readonly) {
      this.selectedRating = rating;
      this.ratingClicked.emit(rating);
    }
  }
}
