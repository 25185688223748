<div *ngIf="formControlRef?.invalid && (formControlRef?.dirty || formControlRef?.touched)">
  <ol *ngIf="formControlRef?.errors" class="list-group">
    <li class="list-group-item list-group-item-danger" *ngIf="formControlRef?.errors?.['required']">
      <p>Необходимо заполнить поле</p>
    </li>
    <li class="list-group-item list-group-item-danger" *ngIf="formControlRef?.errors?.['minlength']">
      <p>Минимальная длина текста {{formControlRef?.errors?.['minlength'].requiredLength}}</p>
    </li>
    <li class="list-group-item list-group-item-danger" *ngIf="formControlRef?.errors?.['maxlength']">
      <p>Максимальная длина текста {{formControlRef?.errors?.['maxlength'].requiredLength}}</p>
    </li>
  </ol>

</div>
