import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ReviewRateService {
  baseUrl = '/api/review';

  constructor(private http: HttpClient) {
  }

  addLike(reviewId: number): Observable<void> {

    return this.http.post<void>(`${this.baseUrl}/add-like/${reviewId}`, null);
  }

  addDislike(reviewId: number): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/add-dislike/${reviewId}`, null);
  }

  removeLike(reviewId: number): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/remove-like/${reviewId}`, null);
  }

  removeDislike(reviewId: number): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/remove-dislike/${reviewId}`, null);
  }
}
