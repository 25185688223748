<div class="login-page-wrapper">
  <div class="container">
    <div class="row">
      <div class="offset-md-3 col col-md-6">
        <div [class.mat-elevation-z1]="true" class="login-container">
          <h5>Авторизация</h5>
          <form [formGroup]="form">
            <div class="form-group mt-2">
              <label for="email">Email</label>
              <input class="form-control" formControlName="email" id="email" type="email">
            </div>
            <div class="form-group mt-2">
              <label for="password">Пароль</label>
              <input class="form-control" formControlName="password" id="password" type="password">
            </div>
            <button (click)="login()" class="w-100 mt-2" color="primary" mat-flat-button type="submit">Войти</button>
            <div class="registration-link-container">
              <a class="registration-link" routerLink="/registration">Регистрироваться</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
