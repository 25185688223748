<div class="footer bg-white">
  <div class="container">
    <mat-divider></mat-divider>

    <div class="row mt-3 mb-2">
      <div class="col-md-5 col-12">
        <ul class="list-group list-group-light list-group-small p-0">
          <li class="list-group-item px-3 border-0 p-0">
            <h6 class="px-2">О приложении</h6>
          </li>
          <li class="list-group-item px-3 border-0 p-0">
            <a class="btn p-2 m-0" href="#">Политика конфиденциальности</a>
          </li>
          <li class="list-group-item px-3 border-0 p-0">
            <a class="btn p-2 m-0" href="#">Условия и положения</a>
          </li>
        </ul>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="row mt-2">
      <div class="col">
        <p>Копирование разрешается только с письменного разрешения администрации сайта.</p>
        <p>По вопросам работы сайта пишите на karim20.ks&#64;gmail.com</p>
      </div>
    </div>
  </div>

</div>
