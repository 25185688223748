import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomePageComponent} from './page/home/home-page.component';
import {ReviewDetailPageComponent} from './page/review/review-detail-page/review-detail-page.component';
import {
  SearchReviewObjectPageComponent
} from './page/review/search-review-object-page/search-review-object-page.component';
import {ReviewItemPageComponent} from './page/review/review-item-page/review-item-page.component';
import {CategoryPageComponent} from './page/category-page/category-page.component';
import {LoginComponent} from './page/login/login.component';
import {RegistrationComponent} from './page/registration/registration.component';
import {UserPageComponent} from './management/user-page/user-page.component';
import {UserReviewsPageComponent} from './management/user-reviews-page/user-reviews-page.component';
import {
  UserReviewModificationPageComponent
} from './management/user-review-modification-page/user-review-modification-page.component';
import {UserReviewCreateComponent} from './management/user-review-create/user-review-create.component';
import {NotFoundComponent} from './page/error/not-found/not-found.component';

const routes: Routes = [
  {path: 'review/:reviewUrl', component: ReviewDetailPageComponent},
  {path: 'topic/:topicUrl', component: ReviewItemPageComponent},
  {path: 'reviews', component: SearchReviewObjectPageComponent},
  {path: 'categories', component: CategoryPageComponent},
  {path: 'login', component: LoginComponent},
  {path: 'registration', component: RegistrationComponent},
  {path: 'user', component: UserPageComponent},
  {path: 'user/reviews', component: UserReviewsPageComponent},
  {path: 'user/review/add', component: UserReviewCreateComponent},
  {path: 'user/review/:reviewId', component: UserReviewModificationPageComponent},
  // {path: 'user/review-topic', component: UserReviewObjectsPageComponent},
  // {path: 'user/review-object/add', component: UserReviewObjectCreateComponent},
  // {path: 'user/review-object/:objectId', component: UserReviewObjectUpdateComponent},
  {path: 'not-found', component: NotFoundComponent},
  {path: '', component: HomePageComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
