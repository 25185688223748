import {Component, Input, OnInit} from '@angular/core';
import {CategoryService} from '@core/service/category.service';
import {Category} from '@core/model/category';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AuthService} from '@core/service/auth.service';
import {ReviewerService} from '@core/service/reviewer.service';
import {Reviewer} from '@core/model/reviewer';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input()
  categoryGroupList: Category[] = [];
  form: FormGroup;
  categoryId?: number;
  @Input()
  reviewer?: Reviewer;



  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private categoryGroupService: CategoryService,
              private authService: AuthService,
              private reviewerService: ReviewerService) {
    this.loadReviewer();
    this.form = this.fb.group({
      search: ['']
    });

    this.authService.scribeOnLoginEvent(() => this.loadReviewer());
  }

  ngOnInit(): void {
    this.categoryGroupService.primaryCategoryGroup()
    .subscribe(value => this.categoryGroupList = value);

    this.route.queryParams.subscribe(value => {
      this.form.get('search')?.setValue(value['q']);
      this.categoryId = value['category'];
    });
  }

  search() {
    const searchQuery = this.form?.get('search')?.value ?? '';
    this.router.navigate([`reviews`],
      {
        queryParams: this.getParams(searchQuery, this.categoryId)
      }
    );
  }

  showCategoryReviewObjects(categoryId: number) {
    window.scrollTo(0, 0);

    this.router.navigate([`reviews`],
      {
        queryParams: this.getParams(this.form?.get('search')?.value, categoryId)
      }
    );
  }

  getParams(searchQ?: string, categoryId?: number) {
    let params = {};
    if (categoryId) {
      params = {...params, category: categoryId};
    }
    if (searchQ && searchQ.replaceAll(' ', '').length > 0) {
      params = {...params, q: searchQ};
    }

    return params;
  }

  logout() {
    this.authService.logout();
    this.reviewer = undefined;
    this.router.navigate(['']).then();
  }

  loadReviewer() {
    if (this.authService.isAuth()) {
      this.reviewerService
      .getReviewerInfo()
      .subscribe(reviewer => this.reviewer = reviewer);
    }
  }
}
