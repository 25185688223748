import {Component, OnInit} from '@angular/core';
import {CategoryService} from '@core/service/category.service';
import {Category} from '@core/model/category';

@Component({
  selector: 'app-category-page',
  templateUrl: './category-page.component.html',
  styleUrls: ['./category-page.component.css']
})
export class CategoryPageComponent implements OnInit {
  catalog: Category[] = [];

  constructor(private categoryService: CategoryService) {
  }

  ngOnInit(): void {
    this.categoryService.getCatalog()
    .subscribe(value => this.catalog = value);
  }

}
