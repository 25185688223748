import {Component, Input, OnInit} from '@angular/core';
import {ReviewService} from '@core/service/review.service';
import {Review} from '@core/model/review';
import {Router} from '@angular/router';
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
})
export class HomePageComponent implements OnInit {
  @Input()
  lastReviews: Review[] = [];
  bestReviews: Review[] = [];
  bestReviewPage = 0;

  constructor(private reviewsService: ReviewService,
              private router: Router,
              private deviceService: DeviceDetectorService) {
  }

  ngOnInit(): void {
    this.reviewsService.lastReviews().subscribe(value => this.lastReviews = value);
    this.reviewsService.bestReviews(this.bestReviewPage, 5)
    .subscribe(value => this.bestReviews = value);
  }

  showMore(sort: string) {
    window.scrollTo(0, 0);
    this.router.navigate([`reviews`],
      {
        queryParams: {sort}
      }
    ).then();
  }

  nextBestReviews() {
    this.bestReviewPage = this.bestReviewPage + 1;

    this.reviewsService.bestReviews(this.bestReviewPage, 5)
    .subscribe(value => this.bestReviews.push(...value));
  }

  isMobile(): boolean {
    return this.deviceService.isMobile();
  }
}
