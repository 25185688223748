<div>
  <div class="editor_container">
    <ngx-editor-menu [editor]="editor"
                     [toolbar]="toolbar"
                     [customMenuRef]="customMenu">
    </ngx-editor-menu>
    <ngx-editor class="editor_element"
                [editor]="editor"
                [placeholder]="''">
    </ngx-editor>
  </div>

  <ng-template #customMenu>
    <app-review-editor-menu [editor]="editor"></app-review-editor-menu>
  </ng-template>
</div>
