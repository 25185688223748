import {Component, Input, OnInit} from '@angular/core';
import {Review} from '@core/model/review';

@Component({
  selector: 'app-review-card',
  templateUrl: './review-card.component.html',
  styleUrls: ['./review-card.component.css']
})
export class ReviewCardComponent implements OnInit {
  @Input()
  public review?: Review;
  @Input()
  public sizeType = 'full';

  ngOnInit(): void {
    if (this.review) {
      this.review.likes = this.review?.likes ?? 0;
      this.review.dislikes = this.review?.dislikes ?? 0;
      this.review.stars = this.review?.stars ?? 0;
    }
  }

  getUrl(){
    return encodeURIComponent(this.review?.url ?? '')
  }
}
