import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Review, ReviewDetail, SaveReview} from '@core/model/review';
import {Page} from '@core/model/page';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {
  baseUrl = '/api/review';

  constructor(private http: HttpClient) {
  }

  searchReview(q: string, page: number, size: number): Observable<Page<Review>> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('q', q);
    queryParams = queryParams.append('page', page);
    queryParams = queryParams.append('size', size);

    return this.http.get<Page<Review>>(`${this.baseUrl}/search`, {params: queryParams});
  }

  lastReviews(): Observable<Review[]> {
    return this.http.get<Review[]>(`${this.baseUrl}/last`);
  }

  bestReviews(page: number, size: number): Observable<Review[]> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('page', page);
    queryParams = queryParams.append('size', size);
    return this.http.get<Review[]>(`${this.baseUrl}/best`, {params: queryParams});
  }

  popularReviews(): Observable<Review[]> {
    return this.http.get<Review[]>(`${this.baseUrl}/popular`);
  }

  getReview(url: string): Observable<ReviewDetail> {
    return this.http.get<ReviewDetail>(`${this.baseUrl}/${decodeURIComponent(url)}`);
  }

  getReviewByTopic(url: string, page: number, size: number): Observable<Page<Review>> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('page', page);
    queryParams = queryParams.append('size', size);
    return this.http.get<Page<Review>>(`${this.baseUrl}/by_topic/${url}`, {params: queryParams});
  }

  saveReview(saveReview: SaveReview): Observable<ReviewDetail> {
    const formData = new FormData();
    if (saveReview.topicUrl)
      formData.append('topicUrl', saveReview.topicUrl)
    formData.append('topicTitle', saveReview.topicTitle)
    formData.append('topicDescription', saveReview.topicDescription)
    formData.append('topicCategoryId', saveReview.topicCategoryId.toString())

    formData.append('reviewTitle', saveReview.reviewTitle)
    formData.append('reviewRating', saveReview.reviewRating)
    formData.append('reviewText', saveReview.reviewText)
    formData.append('reviewShortDescription', saveReview.reviewShortDescription)

    saveReview.topicImages.forEach((value, index) => {
      formData.append(`topicImages[${index}]`, value)
    });

    saveReview.reviewCoverImages.forEach((value, index) => {
      formData.append(`reviewCoverImages[${index}]`, value)
    });

    return this.http.post<ReviewDetail>(`${this.baseUrl}`, formData);
  }
}
