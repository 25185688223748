<div *ngIf="reviewTopic" [class.mat-elevation-z1]="true" class="review-object-card-inline">
  <div class="row">

    <div class="review-object-card-inline__carousel-wrapper col-md-4 col-12">
      <app-carousel-image *ngIf="reviewTopic && reviewTopic.media" [images]="reviewTopic.media" mode="sm"></app-carousel-image>
    </div>

    <div class="card-body mt-3 mt-md-0 col-md-8 col-12">
      <h5>
        <a class="card-title" routerLink="/topic/{{getUrl()}}"
           routerLinkActive="active">{{reviewTopic.name}}</a>
      </h5>
      <p class="card-text review-object-card-inline__text">{{reviewTopic.shortDescription}}</p>

      <div class="d-flex align-items-end justify-content-between align-items-center">
        <div>
          <p class="m-0">
            <a [queryParams]="{category: reviewTopic.category.id}"
               [routerLink]="['/reviews']">{{reviewTopic.category.name}}</a>
          </p>
        </div>
        <div class="d-flex">
          <div class="d-flex align-items-center">
            <app-rating [readonly]="true" [selectedRating]="reviewTopic.rating"></app-rating>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

