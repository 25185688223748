import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AbstractControl} from "@angular/forms";

@Component({
  selector: 'app-form-error-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './form-error-list.component.html',
  styleUrl: './form-error-list.component.css'
})
export class FormErrorListComponent {

  @Input()
  formControlRef?: AbstractControl | null;
}
