export enum ReviewStatus {
  DRAFT='DRAFT',
  MODERATION='MODERATION',
  REJECTED='REJECTED',
  PUBLISHED='PUBLISHED',
  DELETED='DELETED'
}


export function getStatusName(reviewStatus?: string) {
  if (reviewStatus == ReviewStatus.DRAFT.toString()) {
    return 'Черновик';
  } else if (reviewStatus == ReviewStatus.MODERATION.toString()) {
    return 'На модерации';
  } else if (reviewStatus == ReviewStatus.REJECTED.toString()) {
    return 'Отказано';
  } else if (reviewStatus == ReviewStatus.DELETED.toString()) {
    return 'Удалено';
  } else if (reviewStatus == ReviewStatus.PUBLISHED.toString()) {
    return 'Опубликовано';
  }

  return reviewStatus;
}
