<div *ngIf="review" class="container mt-3 mb-3">
  <div class="row">
    <div class="col-md-9 col-12">
      <section [class.mat-elevation-z1]="true" id="review_object">
        <div class="row">
          <div class="col">
            <h2 class="text-center mt-2">{{review.reviewTopic.name}}</h2>
            <app-carousel-image *ngIf="review && review.reviewTopic && review.reviewTopic.media" [images]="review.reviewTopic.media"></app-carousel-image>
            <div class="d-flex align-items-end">
              <app-rating [readonly]="true" [selectedRating]="review.reviewTopic.rating"></app-rating>
              <span class="mb-1 mx-2">{{review.reviewTopic.rating}}/5</span>
            </div>
          </div>
        </div>
      </section>

      <div class="col-md-3 col-12" *ngIf="isMobile()">
        <app-simple-yandex-ad-block></app-simple-yandex-ad-block>
      </div>

      <section [class.mat-elevation-z1]="true" class="mt-3" id="review">
        <div class="row">
          <div class="col">
            <div class="d-flex justify-content-between align-items-center">
              <div class="user-info-container">
                <img [src]="review.userInfo.iconLink" alt="user_icon" loading="lazy">
                <div class="user-info">
                  <p [innerText]="review.userInfo.userName"></p>
                  <app-rating [readonly]="true" [selectedRating]="review.stars"></app-rating>
                </div>
              </div>
              <div>
                <p>{{review.publishedDate | date : 'yyyy-MM-dd HH:mm' }}</p>
              </div>
            </div>
            <h2>{{review.title}}</h2>
            <div *ngFor="let block of review.blocks" class="mt-2">
              <div [innerHTML]="trustHtml(block.text)" class="mt-3 review_block">
              </div>
            </div>
          </div>
        </div>

        <button routerLink="/topic/{{getUrl()}}" class="w-100 bg-white mt-3" mat-stroked-button>
          Читать все отзывы
        </button>
      </section>
    </div>

    <div class="col-md-3 col-12" *ngIf="!isMobile()">
      <app-simple-yandex-ad-block></app-simple-yandex-ad-block>
    </div>
  </div>
</div>


<div *ngIf="!review" class="h-100 mt-4">
  <app-circuit-loader>
  </app-circuit-loader>
</div>

<app-floor-adsblock/>
