import {Component, Input} from '@angular/core';
import {ReviewTopic} from '@core/model/review';

@Component({
  selector: 'app-review-object-card-inline',
  templateUrl: './review-object-card-inline.component.html',
  styleUrls: ['./review-object-card-inline.component.css']
})
export class ReviewObjectCardInlineComponent {
  @Input()
  reviewTopic?: ReviewTopic;

  getUrl() {
    return encodeURIComponent(this.reviewTopic?.url ?? '');
  }
}
