import {Component, Inject, isDevMode, OnInit, PLATFORM_ID, Renderer2, RendererFactory2} from '@angular/core';
import {CommonModule, isPlatformBrowser} from '@angular/common';
import {DeviceDetectorService} from "ngx-device-detector";
import {environment} from "environments/environment";

@Component({
  selector: 'app-floor-adsblock',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './floor-adsblock.component.html',
  styleUrl: './floor-adsblock.component.css'
})
export class FloorADSBlockComponent implements OnInit {
  private renderer: Renderer2;
  isBrowser = false;

  constructor(public rendererFactory: RendererFactory2,
              private deviceService: DeviceDetectorService,
              @Inject(PLATFORM_ID) private platformId: any) {
    this.isBrowser  = isPlatformBrowser(this.platformId);
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  ngOnInit() {
    this.loadScript();
  }

  loadScript(): void {
    if (!isDevMode() && this.isBrowser && environment.showAds) {
      if (this.deviceService.isMobile()) {
        const script = this.renderer.createElement('script');
        script.text = `
          window.yaContextCb.push(() => {
              Ya.Context.AdvManager.render({
                  "blockId": "R-A-9462968-2",
                  "type": "floorAd",
                  "platform": "touch"
              })
          })
      `;
        this.renderer.appendChild(document.body, script);
      } else {
        const script = this.renderer.createElement('script');
        script.text = `
          window.yaContextCb.push(() => {
              Ya.Context.AdvManager.render({
                  "blockId": "R-A-9462968-3",
                  "type": "floorAd",
                  "platform": "desktop"
              })
          })
      `;
        this.renderer.appendChild(document.body, script);
      }
    }
  }
}
