<div class="container">
  <!--  <div class="row">-->
  <!--    <div class="col mt-3">-->
  <!--     -->
  <!--    </div>-->
  <!--  </div>-->
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12">
        <div class="review_main_data">
          <div class="form-group">
            <label for="reviewTopicTitle">Тема рецензии</label>
            <input class="form-control mb-2" id="reviewTopicTitle" formControlName="reviewTopicTitle"
                   name="reviewTopicTitle"
                   type="text">
          </div>

          <div class="form-group">
            <label for="reviewTopicCategory">Категория</label>
            <select class="form-control mb-2" id="reviewTopicCategory" formControlName="reviewTopicCategory"
                    name="reviewTopicCategory">
              <option [value]="category.id" *ngFor="let category of categoryList" [text]="category.name">

              </option>
            </select>
          </div>

          <p>Фотографии на обложке темы</p>
          <div class="d-flex justify-content-around">
            <div class="form-group" *ngFor="let titleImage of [1, 2, 3, 4, 5]">
              <label for="topic_title_img_{{titleImage}}">
                <img ngSrc="assets/img/no-image.jpg" alt="no_content" class="title_img_preview"
                     id="topic_title_img_preview_{{titleImage}}"
                     height="100" width="150" *ngIf="!this.topicImageUrls.get(titleImage)">

                <img src="/api/photo?url={{this.topicImageUrls.get(titleImage)}}" alt="no_content"
                     class="title_img_preview"
                     id="title_img_preview_{{titleImage}}"
                     height="100" width="150" *ngIf="this.topicImageUrls.get(titleImage)">
              </label>
            </div>
          </div>

          <div class="form-group">
            <label for="reviewTopicDescription">Описание темы</label>
            <textarea class="form-control mb-2" id="reviewTopicDescription" formControlName="reviewTopicDescription"
                      name="reviewTopicDescription"
                      type="text"
                      rows="5">
            </textarea>
          </div>
          <div class="form-group">
            <label for="reviewTitle">Заголовок</label>
            <input class="form-control my-2" formControlName="reviewTitle"
                   id="reviewTitle"
                   type="text">
          </div>

          <p>Фотографии на обложке</p>
          <div class="d-flex justify-content-around">
            <div class="form-group" *ngFor="let titleImage of [1, 2, 3, 4, 5]">
              <label for="review_title_img_{{titleImage}}}">
                <img ngSrc="assets/img/no-image.jpg" alt="no_content" class="title_img_preview"
                     id="review_title_img_preview_{{titleImage}}"
                     height="100" width="150" *ngIf="!this.reviewImageUrls.get(titleImage)">

                <img src="/api/photo?url={{this.reviewImageUrls.get(titleImage)}}" alt="no_content"
                     class="title_img_preview"
                     id="review_title_img_preview_{{titleImage}}"
                     height="100" width="150" *ngIf="this.reviewImageUrls.get(titleImage)">
              </label>
            </div>
          </div>


          <div class="form-group mt-2">
            <label>Оценка</label>
            <app-rating [readonly]="true" [selectedRating]="reviewDetail?.stars ?? 1"></app-rating>
          </div>

          <div class="form-group">
            <label for="reviewShortDescription">Краткое описание</label>
            <textarea class="form-control mb-2" formControlName="reviewShortDescription"
                      id="reviewShortDescription"
                      rows="4"></textarea>
          </div>
          <div class="form-group">
            <p class="pb-1 mb-0">Рецензия</p>
            <div class="editor_form_group border border-1 p-1" [innerHTML]="trustHtml(reviewContent)">
            </div>
          </div>
          <p class="review_status"><span>{{ getStatusName(reviewDetail?.status) }}</span></p>
        </div>
      </div>
    </div>
  </form>
</div>
